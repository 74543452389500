<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :body-style="{padding: '24px 32px'}" :bordered='false'>
      <a-form-model ref='form' :model='form' :rules='rules' :labelCol='labelCol' :wrapperCol='wrapperCol'>
        <a-form-model-item label='协议标题' prop='title'>
          <a-input v-model='form.title' />
        </a-form-model-item>
        <a-form-model-item label='是否签名' prop='signature'>
          <a-radio-group v-model='form.signature'>
            <a-radio value='0'>否</a-radio>
            <a-radio value='1'>是</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label='协议状态' prop='status'>
          <a-radio-group v-model='form.status'>
            <a-radio value='online'>上架</a-radio>
            <a-radio value='offline'>下架</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label='显示优先级' prop='priority'>
          <a-input-number v-model='form.priority' />
        </a-form-model-item>
        <a-form-model-item label='协议内容' prop='contentRichText'>
          <quill-editor class='editor' ref='myTextEditor'
                        prefix='protocol/html/'
                        :value='form.contentRichText'
                        @change='handleQuillChange'>
          </quill-editor>
        </a-form-model-item>
        <a-form-model-item label='协议链接' prop='contentRichTextUrl'>
          <a-input :readOnly='true' v-model='form.contentRichTextUrl' placeholder='系统生成无需手动填写' />
        </a-form-model-item>
      </a-form-model>
      <a-form-model-item :wrapper-col=' { lg: { span: 15 ,offset: 7}, sm: { span: 17 ,offset: 7} }'>
        <a-button @click='handleReset'>
          重置
        </a-button>
        <a-divider type='vertical' />
        <a-button @click='handleCancel'>
          取消
        </a-button>
        <a-divider type='vertical' />
        <a-button type='primary' @click='handleSubmit'>
          提交
        </a-button>
      </a-form-model-item>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import fs from 'string-to-file-stream'
import { QuillEditor } from '@/components'
import client from '@/config/oss.config.js'
import { getProtocol, putProtocol } from '@/api/protocol'
import { combine } from '@/config/quill.config'

export default {
  name: 'ProtocolEdit',
  data() {
    return {
      labelCol: { lg: { span: 7 }, sm: { span: 7 } },
      wrapperCol: { lg: { span: 15 }, sm: { span: 17 } },
      loading: false,
      form: {
        id: '',
        title: '',
        status: '',
        priority: 0,
        signature: 0,
        contentRichText: '',
        contentRichTextUrl: ''
      },
      rules: {
        title: [
          { required: true, message: '请填写协议标题', trigger: 'blur' },
          { min: 6, max: 32, message: '协议标题字符数限定6～64个', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择是否发布', trigger: 'change' }
        ],
        signature: [{ required: true, message: '请选择是否需要签名', trigger: 'change' }],
        priority: [{ required: true, message: '请填写显示优先级', trigger: 'change' }],
        contentRichText: [{ required: true, message: '请编辑协议详情', trigger: 'change' }],
        contentRichTextUrl: [{ required: true, message: '编辑协议详情后自动生成', trigger: 'change' }]
      }
    }
  },
  components: { QuillEditor },
  created() {
    this.handleInitial()
  },
  activated() {
    this.handleInitial()
    // debugger;
  },
  methods: {
    async handleQuillChange(html) {
      this.form.contentRichText = html
    },
    handleReset(e) {
      this.$refs.form.resetFields()
    },
    handleCancel(e) {
      this.$router.back()
    },
    handleInitial() {
      const { id } = this.$route.query
      if (id) {
        getProtocol(this.$route.query).then(protocol => {
          this.form = protocol
        })
      }else{
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }

        console.log("这是新建的")
      }
    },
    async handleSubmit(e) {
      e.preventDefault()

      if (this.form.contentRichText) {
        const path = 'protocol/html/' + this.$uuid.v4() + '.html'
        const result = await client.putStream(path, fs(combine(this.form.contentRichText), { path: path }))
        this.form.contentRichTextUrl = result.url
      }


      this.$refs.form.validate((result) => {
        if (result) {
          putProtocol(this.form).then(res => {
            this.$router.back()
          })
        }
      })
    }

  }
}
</script>
